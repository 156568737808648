import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";

function isInteger(value) {
  if(parseInt(value,10).toString()===value) {
    return true
  }
  return false;
}

export const CoreSpacerBlock = (props) => {

  // console.log(props)
  // console.log(isInteger(props.attributes.heightPx))
  // console.log(typeof(props.attributes.heightPx))

  return (
    <div
      className={props.attributes.className ? props.attributes.className : ""}
      style={{
        height: isInteger(props.attributes.heightPx) ?  `${parseInt(props.attributes.heightPx)}px` : props.attributes.heightPx,
      }}
    ></div>
  );
};

CoreSpacerBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

// NOTE: there is a type issue on the height field so using alias avoids the problem
export const CoreSpacerBlockFragment = graphql`
  fragment CoreSpacerBlock on wp_Block {
    ... on wp_CoreSpacerBlock {
      name
      attributes {
        __typename
        ... on wp_CoreSpacerBlockAttributes {
          heightPx: height
          className
        }
        ... on wp_CoreSpacerBlockDeprecatedV1Attributes {
          heightPx: height
          className
        }
      }
    }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CoreSpacerBlockPreview = `
... on CoreSpacerBlock {
    name
    attributes {
      __typename
      ... on CoreSpacerBlockAttributes {
        heightPx: height
        className
      }
      ... on CoreSpacerBlockDeprecatedV1Attributes {
        heightPx: height
        className
      }
    }
  }
`;  