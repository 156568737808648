import React from "react"
import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import Grid from "@material-ui/core/Grid"
import styles from "./styles"
import weddingStyles from "./weddingStyles"

const useStyles = makeStyles(theme => styles(theme))
const useWeddingStyles = makeStyles(theme => weddingStyles(theme))

export const AcfImageContentSplitBlock = props => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  )
  const classes = useStyles()
  const weddingClasses = useWeddingStyles()

  let useClasses = classes
  if (props.pageContext.wedding) {
    useClasses = weddingClasses
  }

  return (
    <div className={`image-content-split ${useClasses.imageContentSplit}`}>
      <Grid
        container
        spacing={3}
        direction={props.block.blockImageContentSplit.imageRight ? "row-reverse" : "row"}
        alignItems="center"
      >
        <Grid item xs={12}>
          {blocks[0]}
        </Grid>
        <Grid item xs={12} md={6} lg={7}>
          {blocks[1]}
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          {blocks[2]}
          {blocks[3]}
          {blocks[4]}
        </Grid>
      </Grid>
    </div>
  )
}

export const AcfImageContentSplitBlockFragment = graphql`
  fragment AcfImageContentSplitBlock on wp_Block {
    ... on wp_AcfImageContentSplitBlock {
      name
      blockImageContentSplit {
        imageRight
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfImageContentSplitBlockPreview = `
    ... on AcfImageContentSplitBlock {
        name
        blockImageContentSplit {
          imageRight
        }
    }
`
